<template>
  <div class="home">
    <div class="swiper" v-if="bannerList.length">
      <div class="swiper-container" id="advertising">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in bannerList" :key="index">
            <img :src="`${imgPath}${item.bannerUrl}`" />
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <div class="home-bottom">
      <img src="@/assets/home-bottom-icon1.jpg" @click="goDetail('/ocr')"/>
      <img src="@/assets/home-bottom-icon2.jpg" @click="goDetail('/text')" />
      <img src="@/assets/home-bottom-icon3.jpg" @click="goDetail('/edit')" />
    </div>
  </div>
</template>

<script>
import "swiper/swiper-bundle.min.css";
import Swiper from 'swiper/bundle';
import { getBannerList , hasXml} from '@/api/user'
import { getToken } from '@/utils/auth'

export default {
  components: {},
  data() {
    return {
      imgPath: process.env.VUE_APP_IMG_PATH,
      bannerList: [],
      loginPath: '',
      swiper: null,
      checkOne: false
    }
  },
  async created() {
    const res = await getBannerList()
    this.bannerList = res.rows
    this.loginPath = process.env.VUE_APP_LOGIN_PATH

    setTimeout(() => {
      this.swiper = new Swiper('#advertising', {
        initialSlide: 0,
        loop: true,
        autoplay : {
          delay: 10000
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      });
    }, 500)
  },
  methods: {
    onSlideChange() {
      console.log('333')
    },
    async goDetail(path) {
      if (this.checkOne) return
      this.checkOne = true

      if (!getToken()) {
        this.checkOne = false
        location.href = this.loginPath
        
      } else {
        if (path == '/ocr') {
          this.checkOne = false
          return this.$router.push(path)
        }

        let res = await hasXml()
        if (res.data) {
          this.checkOne = false
          this.$router.push(path)
        } else {
          this.$message.warning(
            `您当前还无文本，5s后将进入图像识别`
          );

          this.timer = setTimeout(() => {
            this.checkOne = false
            this.$router.push('/ocr')
            clearTimeout(this.timer)
          }, 5000)
        }
      }

    }
  },
};
</script>
<style lang="less">
.home {
  .swiper-container {
    .swiper-pagination {
      bottom: 50px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .swiper-pagination-bullet {
      width: 24px;
      height: 7px; 
      border-radius: 5px;
      background-color: #ccccff;
      opacity: 1;
      margin-right: 4px;
    }

    .swiper-pagination-bullet-active {
      background-color: #6699cc;
    }

    .swiper-slide img {
      width: 100vw;
      // max-height: 630px;
    }
  }

  .home-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 80px auto;
    img {
      margin-right: 20px;
      cursor: pointer;
      &:nth-child(3) {
        margin-right: 0;
      }
    }
  }
}

.footer-wrap {
  background: #fff;
}

</style>

